import React, { useEffect, createRef } from 'react'
import lottie from 'lottie-web'
import animation from '../404/lf30_editor_UDNKr6.json'

const style = {
  width: '70%',
  margin: '0 auto',
}
const Anima404 = ({ isOn, handleToggle }) => {
  let animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      hideOnTransparent: true,
      animationData: animation,
      // path: 'https://assets8.lottiefiles.com/datafiles/1x50a9By5yYEZ8A/data.json'
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [])

  return (
    <>
      <div
        className="animation-container"
        ref={animationContainer}
        style={style}
      />
    </>
  )
}

export default Anima404
