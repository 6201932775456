import React from 'react'
import Container from '../components/container'
import Wrapper from '../components/wrapper/wrapper'
import Anima404 from '../components/Animations/404/404_animation'

const fourofourPage = {}

const NotFoundPage = () => (
  <Container>
    <Wrapper>
      <div style={fourofourPage}>
        <a href="https://www.crea.ca/">
          <img
            style={{ paddingTop: '20px' }}
            src={require('../images/logo_crea_EN.svg')}
          />
        </a>
        <Anima404 />

        <h1>...Oops, Page Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... </p>
        <p>You can return back to</p>
        <h3>
          <a href="https://www.crea.ca/">CREA.ca</a>
        </h3>
        <h3>
          <a href="https://creastats.crea.ca/">CREAStats.ca</a>
        </h3>
      </div>
    </Wrapper>
  </Container>
)

export default NotFoundPage
