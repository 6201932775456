import React from 'react'

export default ({ children }) => (
  <div
    style={{ paddingBottom: 20, margin: '0 auto', overflowX: 'hidden' }}
    style={{
      backgroundColor: 'var(--bg)',
      color: 'var(--textNormal)',
      transition: 'color 0.2s ease-out, background 0.2s ease-out',
    }}
  >
    {children}
  </div>
)

// maxWidth: 1180, margin: '0 auto'
